<script>
import Layout from "../../layouts/horizontal";
import PageHeader from "@/components/page-header";
import http from '@/http'

import {
   authComputed
} from "@/state/helpers";

export default {
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      typeList:[
        { id:'Login', text: 'Login'},
        { id:'Logout', text: 'Logout'},
        { id:'OpenPage', text: 'Open Page'},
        { id:'ApiCall', text: 'Api Call'}
      ],
      categoryList:[
        { id:'General', text: 'General'},
        { id:'Error', text: 'Error'}
      ],
    
      category: null,
      type: null,
      keyword: null,
      from: null,
      till: null,
      ipAddress: null,
      user: null,

      title: this.$t("pages.administration.audit.title"),

      showmodal: false,
  
      submitted: false,
      selectedItem: null,
      items:[],
      isBusy: false,
      currentPage: 1,
      perPage: 20,
      totalItems: 0,

      breadcrumbs: [
        {
          text: "C8 Studio",
          to: { name: 'home' }
        },
        {
          text: this.$t("pages.administration.title")
        },
        {
          text: this.$t("pages.administration.audit.title"),
          active: true
        }
      ]
    };
  },
  created(){
    this.load();
  },
  computed: {
    ...authComputed,
    totalPages() {
      return Math.ceil(this.totalItems / this.perPage);
    }
  },
  watch: {
    currentPage: {
      handler: function () {
        this.load().catch(error => {
          console.error(error)
        })
      }
    }
  },
  methods: {
    applyFilters(){
      if(this.currentPage !== 1)
        this.currentPage = 1;
      else 
        this.load();
    },
    async load()  {
      this.isBusy = true;
      let p = {
        keyword: this.keyword,
        take: this.perPage,
        skip: this.perPage * (this.currentPage - 1),
        type: this.type,
        category: this.category,
        user: this.user,
        from: this.from,
        till: this.till,
        ipAddress: this.ipAddress
      };
      const resp = await http.post(`api/audit/search`, p);
      this.items = resp.data.items.map(x => { return {...x, expanded: false }; });
      this.totalItems = resp.data.total;
      this.isBusy = false;  
    },
    hideModal() {
      this.showmodal = false;
      this.selectedItem = null;
    },
    showModal(item) {
      this.showmodal = true;
      this.selectedItem =item;
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="breadcrumbs" />
    <div class="row">
      <div class="col-12">
      <div class="email-leftbar card cardc8 p-0">
        <div class="card-content m-3">
         <b-form-group
            class="mt-2"
            :label="$t('pages.administration.audit.category')"
            label-for="category"
          >
          <select class="form-control" v-model="category" id="category">
            <option :value="null">--- Select category ---</option>
            <option v-for="t in categoryList" :value="t.id" :key="t.id">
              {{t.text}}
            </option>
          </select>
        </b-form-group>
        <b-form-group
            class="mt-2"
            :label="$t('pages.administration.audit.type')"
            label-for="category"
          >
          <select class="form-control" v-model="type" id="category">
            <option :value="null">--- Select type ---</option>
            <option v-for="t in typeList" :value="t.id" :key="t.id">
              {{t.text}}
            </option>
          </select>
        </b-form-group>
        <b-form-group
            class="mt-2"
            :label="$t('common.keyword')"
            label-for="keywords"
          >
          <div class="search-box">
            <div class="position-relative">
              <input type="text" class="form-control rounded" v-model="keyword" :placeholder="$t('common.keyword')"  id="keywords"/>
              <i class="mdi mdi-magnify search-icon"></i>
            </div>
          </div>
        </b-form-group>

        <b-form-group
            class="mt-2"
            :label="$t('pages.administration.audit.ipAddress')"
            label-for="ipAddress"
          >
          <div class="search-box">
            <div class="position-relative">
              <input type="text" class="form-control rounded" v-model="ipAddress"   id="ipAddress"/>
              <i class="mdi mdi-magnify search-icon"></i>
            </div>
          </div>
        </b-form-group>

        <b-form-group
            class="mt-2"
            :label="$t('pages.administration.audit.userName')"
            label-for="user"
          >
          <div class="search-box">
            <div class="position-relative">
              <input type="text" class="form-control rounded" v-model="user"   id="user"/>
              <i class="mdi mdi-magnify search-icon"></i>
            </div>
          </div>
        </b-form-group>

        <b-form-group
            class="mt-2"
            :label="$t('pages.administration.audit.from')"
            label-for="from"
          >
          <b-form-datepicker id="from" v-model="from"></b-form-datepicker>
        </b-form-group>

         <b-form-group
            class="mt-2"
            :label="$t('pages.administration.audit.till')"
            label-for="till"
          >
          <b-form-datepicker id="till" v-model="till"></b-form-datepicker>
        </b-form-group>
         <hr/>
          <b-form-group>
          <button class="btn btn-block btn-secondary" @click="applyFilters">{{$t("common.applyFilters")}}</button>
        </b-form-group>
      
       </div>
      </div>


        <div class="email-rightbar mb-2">
          <div class="card cardc8 p-0">
            <div class="card-content m-3">
            <div class="card-body">
              <div class="table-responsive mt-3" style="position: relative;">
                <table
                  class="table table-centered table-sm datatable dt-responsive nowrap"
                  style="border-collapse: collapse; border-spacing: 0; width: 100%;"
                >
                  <thead class="thead-light">
                    <tr>
                      
                      <th>{{$t("pages.administration.audit.category")}}</th>
                      <th>{{$t("pages.administration.audit.type")}}</th>
                      <th>{{$t("pages.administration.audit.userName")}}</th>
                      <th>{{$t("pages.administration.audit.ipAddress")}}</th>
                      <th>{{$t("pages.administration.audit.insertedAt")}}</th>
                      <th></th>
                      <th style="width: 40px; max-width:40px;"></th>
                   
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(item, index) in items" >
                      <tr :key="index">
                        <td>{{item.category}}</td>
                        <td>{{item.type}}</td>
                        <td>{{item.userName}}</td>
                        <td>{{item.ipAddress}}</td>
                        <td>{{item.insertedAt | moment("LLLL")}}</td>
                        <td>
                          <strong v-if="item.type === 'ApiCall'">{{item.parameters["Method"]}}:</strong>
                          {{item.text}}
                        </td>
                        <td>
                          <a href="javascript:void(0)" v-if="Object.keys(item.parameters).length > 0 && item.expanded === false" @click="item.expanded = true">
                            <i class="fa fa-angle-down"></i>
                          </a>
                          <a href="javascript:void(0)" v-if="Object.keys(item.parameters).length > 0 && item.expanded === true" @click="item.expanded = false">
                            <i class="fa fa-angle-up"></i>
                          </a>
                        </td>
                      </tr>
                      <tr v-if="item.expanded === true"  :key="index">
                        <td colspan="1000" style="border-top:0">
                          <dl>
                            <template v-for="(propName, index) in Object.keys(item.parameters)" >
                              <dt :key="index" v-if="item.parameters[propName]">{{propName}}</dt>
                              <dd style="padding-left:20px" v-if="item.parameters[propName]" :key="index">{{item.parameters[propName]}}</dd>
                            </template>
                          </dl>
                        </td>
                      </tr>
                    </template>
                    
                  </tbody>
                </table>
                <div style="position:absolute; left:0; right:0; top:0; bottom:0; background-color: rgba(255,255,255,0.5); text-align: center; padding-top:10%;"
                  v-if="isBusy">
                  <span>
                    <i class="fa fa-spin fa-spinner" style="font-size:3rem"></i>
                  </span>
                </div>
              </div>
              <div class="row mt-1">
                <div class="col"><span class="text-muted" v-if="totalItems">{{$t("common.page")}} {{ currentPage }} {{$t("common.from")}} {{ totalPages }}</span></div>
                <div class="col text-right">
                  <b-pagination class="justify-content-center justify-content-sm-end m-0"
                                v-if="totalItems"
                                v-model="currentPage"
                                :total-rows="totalItems"
                                :per-page="perPage"
                                size="sm" />
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modal -->
    <!-- <b-modal
      id="modal-1"
      v-model="showmodal"
      :title="editedItemId ? $t('common.editRecord') :  $t('common.newRecord')"
      title-class="text-dark font-18"
      hide-footer
    >
      <form @submit.prevent="handleSubmit" autocomplete="off">
         <b-alert
          variant="danger"
          class="mt-3"
          v-if="errors.length > 0"
          show
          dismissible
          >
          <ul>
            <li v-for="e in errors" :key="e">{{e}}</li>
          </ul>
        </b-alert>

         <div class="form-group">
          <label for="userName">{{$t('forms.userEdit.userName')}} [*]</label>
          <input
            id="userName"
            v-model="editedItem.userName"
            type="text"
            class="form-control"
            placeholder=""
            :class="{ 'is-invalid': submitted && $v.editedItem.userName.$error }"
          />
          <div
            v-if="submitted && !$v.editedItem.userName.required"
            class="invalid-feedback"
          >{{$t('forms.userEdit.userName-required')}}</div>
        </div>
        <div class="form-group">
          <label for="name">{{$t('forms.userEdit.name')}} [*]</label>
          <input
            id="name"
            v-model="editedItem.name"
            type="text"
            class="form-control"
            placeholder=""
            :class="{ 'is-invalid': submitted && $v.editedItem.name.$error }"
          />
          <div
            v-if="submitted && !$v.editedItem.name.required"
            class="invalid-feedback"
          >{{$t('forms.userEdit.name-required')}}</div>
        </div>
        
        <div class="form-group">
          <label for="abbr">{{$t('forms.userEdit.abbr')}}</label>
          <input
            id="abbr"
            v-model="editedItem.abbr"
            type="abbr"
            name="abbr"
            class="form-control"
          />
        </div>

        <div class="form-group" v-if="editedItemId">
          <label for="color">{{$t('forms.userEdit.color')}}</label>
             <b-form-input id="color" type="color" name="color" v-model="editedItem.color"></b-form-input>
        </div>

        <div class="form-group">
          <label for="phoneNumber">{{$t('forms.userEdit.phoneNumber')}}</label>
          <input
            id="phoneNumber"
            v-model="editedItem.phoneNumber"
            type="text"
            class="form-control"
           />
        </div>
        
        <div class="form-group">
          <label for="email">{{$t('forms.userEdit.email')}}</label>
          <input
            id="email"
            v-model="editedItem.email"
            type="text"
            class="form-control"
           />
        </div>

        <div class="form-group">
          <label>{{$t('forms.userEdit.disabled')}}</label>
          <b-form-checkbox v-model="editedItem.disabled" switch class="mb-1">
           
          </b-form-checkbox>
        </div>
       
        <div class="form-group" >
          <label>{{$t('forms.userEdit.admin')}}</label>
          <b-form-checkbox v-model="editedItem.isAdmin" switch class="mb-1">
        
          </b-form-checkbox>
        </div>
        
        <div class="form-group" >
          <label>{{$t('forms.userEdit.user')}}</label>
          <b-form-checkbox v-model="editedItem.isUser" switch class="mb-1">
        
          </b-form-checkbox>
        </div>

        <div class="form-group" >
          <label>{{$t('forms.userEdit.publisher')}}</label>
          <b-form-checkbox v-model="editedItem.isPublisher" switch class="mb-1">
        
          </b-form-checkbox>
        </div>

        <div class="form-group" >
          <label>{{$t('forms.userEdit.aiUser')}}</label>
          <b-form-checkbox v-model="editedItem.isAiUser" switch class="mb-1">
        
          </b-form-checkbox>
        </div>

        <div class="form-group">
          <label for="password">{{$t('forms.userEdit.password')}}</label>
          <input
            id="password"
            v-model="editedItem.password"
            type="password"
            name="password"
            class="form-control"
            autocomplete="new-password"
          />  
        </div>
        <div>
          <b-button class="ml-1 float-left" @click="hideModal">{{$t('forms.userEdit.closeButton-title')}}</b-button>
          <button type="submit" class="btn btn-success float-right" :disabled="userSaving">
            <i class="fa fa-spin fa-spinner" v-if="userSaving"></i>
            {{$t('forms.userEdit.saveButton-title')}}
          </button>
        </div>
      </form>
    </b-modal> -->
    <!-- end modal -->
  </Layout>
</template>